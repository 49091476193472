<template>
    <v-card class="w-full">
        <v-toolbar style="margin-bottom: 5px;" elevation="1" flat>
          <v-toolbar-title>
            <span class="headline">Novo Evento</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon large @click="closeDialog">mdi-close</v-icon>
        </v-toolbar>
            <v-col cols="12" md="12" >
                <div class="d-flex flex-wrap justify-space-between">
                    <label
                    v-if="!capaUrl"
                    for="logo"
                    style="cursor: pointer; width: 100%"
                    class="caption d-flex justify-center flex-wrap border mr-4 px-2"
                    title="Add logo cliente"
                    >
                    <v-icon
                        x-large
                        color="primary"
                        for="logo"
                        class="logo-container mb-2"
                        >mdi-cloud-upload</v-icon
                    >
                    <span class="mb-2 w-full text-center"> Foto Principal </span>
                    <input
                        id="logo"
                        style="display: none"
                        type="file"
                        @change="handleUpload('capa', $event)"
                    />
                    </label>
                    <v-img
                    v-else
                    max-height="250"
                    style="width: 15%"
                    class="caption d-flex justify-center flex-wrap border mr-4 px-2"
                    :src="capaUrl"
                    >
                    </v-img>
                    <div
                    v-if="capaUrl"
                    style="cursor: pointer;"
                    class="w-full d-flex justify-end pr-2 mt-2 hover-red"
                    @click="capaUrl = ''"
                    >
                    <v-icon> mdi-delete </v-icon>
                    </div>
                </div>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    v-model="legenda"
                    label="Legenda"
                />
                <v-select
                    v-model="cliente"
                    label="Clientes"
                    :items="estabelecimentos"
                    chips
                    multiple
                />
            </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="btnCancelar" text @click="closeDialog">
              <span class="text-capitalize">Cancelar</span>
            </v-btn>
            <v-btn
              v-if="!edit"
              color="primary"
              type="submit"
              :loading="loadingSalvar"
              @click= "salvarEvento()"
            >
              <span class="text-capitalize">Salvar</span>
            </v-btn>
            <v-btn
              v-else
              color="primary"
              type="submit"
              :loading="loadingSalvar"
              @click= "editarEvento()"
            >
              <span class="text-capitalize">Salvar</span>
            </v-btn>
          </v-card-actions>
      </v-card>

</template>
<script>
import { ProdutoService, EventoService} from "@/services";

export default {
    name: "formularioCadastroEvento",

    props: {
        item: {
            type: [Array, Object],
            default: null
        },

        edit: {
            type: Boolean,
            default: false
        }

    },
    data: () => ({
        loadingSalvar: false,
        capaUrl:'',
        capaTpMidia: '',
        capaId: null,
        legenda: '',
        itemId: null,
        cliente: [],
        estabelecimentos: [],
    }),

    mounted() {
        this.capaUrl = this.item?.midia;
        this.itemId = this.item?.id;
        this.legenda = this.item?.legenda;
        this.cliente = this.item?.clienteId
    },

    beforeDestroy(){
        this.capaUrl = null
        this.itemId = null
        this.legenda = null

    },

    created(){
        this.getEstabelecimentos()

    },

    methods: {
        closeDialog(){
            this.$emit('onClose')
        },
        
        salvarEvento(){
            if(!this.legenda || this.legenda.match(/^ *$/) !== null) {
                return this.$root.showErro("Digite uma legenda")
            }

            if(this.capaUrl == ''){
                return this.$root.showErro("Escolha uma imagem para o evento")
            }

            let payload = {
                legenda: this.legenda,
                midia: this.capaUrl,
                clienteId: this.cliente,
                tpMidia: 'IMAGEM'
            };

            EventoService.cadastroEvento(payload)
            .then((response) => {
                this.$root.showSucesso(response.data);
                this.$emit('success')
            })
            .catch(erro => {
                this.$root.showErro(erro.data);
            })
            .finally(() => {
                this.loadingMidia = false;
            });
        },

        editarEvento(){
            let payload = {
                legenda: this.legenda,
                midia: this.capaUrl,
                id: this.item.id,
                clienteId: this.cliente,
                tpMidia: 'IMAGEM'
            };

            EventoService.editEvento(payload)
            .then((response) => {
                this.$root.showSucesso(response.data);
                this.$emit('success')
            })
            .catch(erro => {
                this.$root.showErro(erro.data);
            })
            .finally(() => {
                this.loadingMidia = false;
            });
        },

        getEstabelecimentos(){
            ProdutoService.getEstabelecimentos()
            .then((response) => {
                this.estabelecimentos = response.data.map(estabelecimento => ({
                    text: estabelecimento.descricao,
                    value: estabelecimento.id
                }));
            })
            .catch(erro => {
                this.$root.showErro(erro.data);
            })
            .finally(() => {
                this.loadingMidia = false;
            });
        },

        handleUpload(type, event) {
        if (event && event.target && event.target.files.length > 0) {
            this.file = event.target.files[0];
            this.formData = new FormData();
            this.formData.append("file", this.file);
            this.formData.append("name", this.file.name);
            ProdutoService.uploadMidia(this.formData)
            .then(response => {
                this.capaUrl = response.data.midiaUrl;
                this.capaTpMidia = response.data.tpMidia;
                this.capaId = response.data.id;
            })
            .catch(erro => {
                this.$root.showErro(erro.data);
            })
            .finally(() => {
                this.loadingMidia = false;
            });         
        }
        }
    }
};
</script>

<style>
.logo-container{
    height: 200px;
    width: 400px;
    border-style: dashed;
}
.w-full {
    width: 100%;
}
</style>