<template>
    <v-container pa-5 fluid>
        <h2 class="mb-5">Eventos</h2>

        <v-card>
        <v-card-text>

            <v-card elevation="1">
            <v-toolbar flat color="white">
                <v-toolbar-title>Eventos</v-toolbar-title>
                <v-divider class="mx-2" inset vertical />
                <v-spacer />
                <v-btn color="primary" class="mb-2" outlined @click="openEvento()">
                Novo Evento
                </v-btn>
            </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="eventos"
                :loading="loading"
                class="elevation-1"
                loading-text="Carregando..."
            >
                <template slot="headerCell" slot-scope="{ header }">
                <span
                    class="subheading font-weight-light text--darken-3"
                    v-text="header.text"
                />
                </template>
                <template v-slot:body="{ items }" ma-5>
                <tbody>
                    <tr v-for="item in items" :key="item.id">
                    <td class="w-10">
                        <v-img contain :src="item.midia" max-width="150px" max-height="150px"/>
                    </td>
                    <td>{{ item.legenda }}</td>
                    <td class="text-right w-10">
                        <v-icon
                        class="mr-2"
                        title="Editar"
                        @click="handleEdit(item)"
                        >mdi-pencil</v-icon
                        >
                        <v-icon
                        class="mr-2"
                        title="Deletar"
                        color="primary"
                        @click="deleteItem(item)"
                        >mdi-delete</v-icon
                        >
                    </td>
                    </tr>
                </tbody>
                </template>
            </v-data-table>
            </v-card>
        </v-card-text>
        </v-card>
        <v-dialog
            v-model="dialogCadastroEvento"
            hide-overlay 
            persistent 
            width="700px"
            class="no-overflow"
        >
        <formulario-cadastro-evento 
            :edit="edit"
            :item="eventoObj"
            :key="key"
            @onClose="dialogCadastroEvento = false"  
            @success="getEventos()"
        />
        </v-dialog>
        <v-dialog
        v-model="loader"
        hide-overlay
        persistent
        width="300"
        >
        <v-card
            color="primary"
            dark
        >
            <v-card-text>
            Aguarde
            <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
            ></v-progress-linear>
            </v-card-text>
        </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { EventoService } from '@/services';
import { Role } from "@/constants";
import  formularioCadastroEvento from './formularioCadastroEvento.vue'

export default {
    components: {
        formularioCadastroEvento
    },

    data() {
        return {
            loader: false,
            loading: false,
            dialogCadastroEvento: false,
            eventoObj: {},
            eventoId: 0,
            eventos: [],
            edit: false,
            headers: [
                { text: "Evento", value: "midia" },
                { text: "Legenda", value: "legenda" },
                { text: "Ações", value: "actions" },
            ],
            key: 0,
        }
    },
    created(){
        this.getEventos()
        if(this.$store.getters["user/getUser"].roleId !== Role.ROOT && this.$store.getters["user/getUser"].roleId !== Role.ADMIN && this.$store.getters["user/getUser"].roleId !== Role.USER){
            this.$router.push('/check-in')
        }
    },

    methods: {
        getEventos() {
            this.dialogCadastroEvento = false 
            this.loading = true
            EventoService.pagination()
            .then(response => {
                this.eventos = response.data
            })
            .finally(() => {
            this.loadingPesquisar = false;
            this.loading = false;
            });
        },

        openEvento(){
            this.eventoObj = []
            this.dialogCadastroEvento = true
            this.key += 1
        },

        handleEdit(item){
            this.loader = true
            EventoService.getEventoDetalhe(item.id)
             .then(response => {
                if(response){
                    this.edit = true
                    this.key += 1
                    this.dialogCadastroEvento = true
                    this.eventoObj = response.data
                    this
                }
            })
            .finally(() => {
            this.loader = false;
            this.loading = false;
            });
        },

        deleteItem(item){
            this.loader = true
            EventoService.deleteEvento(item.id)
             .then(response => {
                if(response){
                    this.getEventos()
                }
            })
            .finally(() => {
            this.loader = false;
            this.loading = false;
            });
        }
    }
}
</script>

<style>
.w-10{
    width: 10%;
}
</style>